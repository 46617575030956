.content-Table {
  border-bottom: unset;
}

.content-table-detail {
  padding-bottom: 0;
  padding-top: 0;
}

.Grid--layout {
  padding-top: 5px;
  padding-bottom: 20px;
  display: flex;
  gap: 12px;
  flex-direction: column;
}

.Grid--row {
  width: 100%;
}

.Grid--columns {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
}
