.add-factory-widget-container {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 5px;
}

.title-container {
  margin-top: 20px;
}

.factory-widget-link-container {
  width: 100vw;
  margin-right: 15px;
}

.factory-widget-buttons-container {
  display: inline-flex;
  margin-right: 0px;
  margin-left: auto;
}

.delete-button-element {
  margin-left: 30px;
}

.fields-required-alert {
  font-size: 1.2em;
  color: #f00;
}
