.search-container {
  display: inline-flex;
  width: 100%;
}

.search-bar-container {
  margin-right: 0px;
  margin-left: auto;
  align-self: center;
  width: 85%;
}

.search-button-element {
  margin-right: auto;
  margin-left: 15px;
  align-self: center;
  right: 0;
  width: 15%;
}
