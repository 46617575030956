.add-container {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 5px;
}

.title-container {
  margin-top: 20px;
}

.area-buttons-container {
  display: inline-flex;
  margin-right: 0px;
  margin-left: auto;
}

.area-button-element + .area-button-element {
  margin-left: 15px;
}

.field-button-element {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;
}
