.title-section {
  padding-top: 7em;
  text-align: center;
}

.title {
  color: #3f51b5;
  font-family: Arial, Helvetica, sans-serif;
  font-size: xx-large;
  font-style: normal;
  font-weight: bold;
  text-transform: uppercase;
}

.subtitle-container {
  padding-top: 1em;
  text-align: center;
}

.subtitle {
  color: #001a33;
  font-family: monospace;
  font-size: xx-large;
  font-style: normal;
  font-weight: lighter;
}
