.thumb-container {
  display: flex;
  justify-content: center;
  width: 100%;
}

.delete-thumb-image-btn-container {
  margin-top: 15px;
}

.ideal-size-message {
  border-radius: 5px;
  padding: 0.5rem;
  width: 100%;
  text-align: center;
  color: red;
  font-style: italic;
  font-size: 0.8em;
}
