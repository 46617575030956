.input-field-wrapper {
  align-content: start;
  display: grid;
  grid-gap: 10px;
  margin-bottom: 20px;
  width: 100%;
}

.input-label {
  color: #2f4f4f;
}

.error-text {
  color: #f00;
  font-size: 10px;
}
