.container {
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 100%;
  width: 40%;
  align-self: center;
  justify-content: center;
  margin-top: 5rem;
  margin-left: auto;
  margin-right: auto;

  .payment-method-btn {
    margin-top: 10px;
  }
}
