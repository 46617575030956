.app-container {
  padding: 1rem;
}

.footer {
  margin-top: 1rem;
}

.vertical-bottom-space {
  margin-bottom: 0.2rem;
}

.error-container {
  align-items: center;
  display: flex;
  justify-content: center;
  padding-top: 7em;
}

.user-message {
  border-radius: 5px;
  padding: 0.5rem;
  width: 100%;
  text-align: center;
  color: red;
  font-style: italic;
  font-size: 0.8em;
}

.recommended-image-category-text {
  text-align: center;
  color: #f00;
}
